import React from 'react';
import Seo from '../components/config/Seo';
import { graphql } from 'gatsby';
import { Typography, Box } from '@material-ui/core';
import marked from 'marked';
import Container from '../components/shared/Container';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(
  ({ palette: { secondary, text }, spacing }) => ({
    container: {
      padding: spacing(20, 0),
      color: text.primary,
      backgroundColor: secondary.main,
      whiteSpace: 'pre-line',
      '& a': {
        color: text.primary,
        textDecoration: 'none',
        position: 'relative',
      },
    },
  })
);

const TermsOfService = ({ data }) => {
  const classes = useStyles();
  return (
    <>
      <Seo title="Terms of Service" />
      <Box className={classes.container}>
        <Container>
          <Typography
            dangerouslySetInnerHTML={{
              __html: marked(
                data.allContentfulTermsOfService.edges[0]
                  .node.policy.policy
              ),
            }}
          />
        </Container>
      </Box>
    </>
  );
};

export const query = graphql`
  query TermsOfServicePage {
    allContentfulTermsOfService(
      sort: { fields: id, order: DESC }
      limit: 1
    ) {
      edges {
        node {
          policy {
            policy
          }
        }
      }
    }
  }
`;

export default TermsOfService;
